import React from "react";
import ReqRichText from "./ReqRichText";
import RichText from './RichText'

export default function FinancialVerifySection2(props) {
  return (
    <div className="financial-verify-section">
      <div className="state-products">
        <div className="container-fluid">
            <div class="row no-gutters">
              <div className="right-image-section">
                  <div className="col-sm-7">
                      <p className="orange-link">{<RichText Description={props?.data?.content?.Paragraph} />}</p>
                    </div>
                    <div className="col-sm-5">
                      <img
                        src={props?.data?.content?.img}
                        className="img-fluid"
                      />
                    </div>
                  </div>
            </div>
        </div>
      </div>
 </div>
  );
}