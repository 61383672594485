import React from "react";
import ReqRichText from "./ReqRichText";
import RichText from './RichText'
import YoutubeEmbed from '../../components/basic/YoutubeEmbed'


export default function Sr22(props) {
  console.log(props, "****sr22");
  console.log("props?.data?.content?", props?.data?.content);
  return (
    <div className="sr22-section">
      <div className="state-products">
        <div className="container-fluid">
          <h1 className="text-center">{props?.data?.content?.Heading}</h1>
            <RichText Description={props?.data?.content?.Paragraph}></RichText>
              {props?.data?.content?.Media_Object_Group.map((item) => {
                if (item.component !== 'Content_Header') {
                  return (
                    <div className="no-gutters">
                      <div className="row">
                        <div className="col-12 col-sm-7">
                          <RichText Description={item.Description} />
                        </div>
                        <div className="col-12 col-sm-5">
                          {/* <img src={props?.data?.content?.img} className="img-fluid" /> */}
                          <YoutubeEmbed embedUrl="vzSnahqF20Q" />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
        </div>
      </div>
 </div>
  );
}
