import React from "react";
import RichText from "./RichText";
import YoutubeEmbed from '../../components/basic/YoutubeEmbed'
export default function DrunkLawSection(props) {
  return (
    <div className="drunk-section">
      <div className="state-products">
        <div className="container-fluid">
          <h1>{props?.data?.content?.Heading}</h1>
          <div className="row no-gutters">
            <div className="col-12 col-sm-12">
              <RichText Description={props?.data?.content?.Paragraph} />
            </div>
            {/* <div className="policy-bottom-text">{props?.data?.content?.Basic[0]?.Description?.content?.map((item) => {
              return <RichText Description={item?.content[0]?.text} />;
            })}</div> */}

            <div className="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
